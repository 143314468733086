/*********************
 *
 * JAVASCRIPT
 *
 *********************/

/*********************
 * IMPORT STYLES
 *********************/

import '../scss/style.scss';
import '../scss/admin.scss';
import '../scss/login.scss';

/*********************
 * IMPORT LIBRARIES (REMOVE IF UNUSED)
 *********************/

import Swiper from 'swiper';
//import {Navigation, Pagination, Autoplay, Thumbs, EffectFade, Grid} from 'swiper/modules';

/*import {
    animateOnScroll,
    advancedSticky,
    parralax,
    svgPathFill
} from './libs/animation';*/

import './libs/cookies.js';
import { localScroll } from './libs/local-scroll';
import { reframe } from './libs/reframe';

/*********************
 * GLOBAL VARIABLES
 *********************/

let clamp;
let prevVpWidth;
let vpWidth;
let vpHeight;

const bodyElement = document.querySelector('body');
const header = document.querySelector('.header');

/*********************
 * CLAMP VARIABLE
 *********************/

function getClamp() {
    clamp = parseFloat(window.getComputedStyle(document.documentElement).getPropertyValue('font-size')) / 16;
}

getClamp();

/*********************
 * GET VIEWPORT DIMENSIONS
 *********************/

function getViewportDimensions() {
    vpWidth = window.innerWidth || document.documentElement.clientWidth;
    vpHeight = window.innerHeight || document.documentElement.clientHeight;
}

getViewportDimensions();

/*********************
 * RESIZE
 *********************/

window.addEventListener(
    'resize',
    throttle(() => {
        getViewportDimensions();
        getClamp();
        adjustMenu();
    }, 75)
);

/*********************
 * PAGE LOAD
 *********************/

document.addEventListener('DOMContentLoaded', (event) => {
    bodyElement.classList.add('js-active');

    const selectors = [
        "iframe[src*='player.vimeo.com']",
        "iframe[src*='vimeo.com']",
        "iframe[src*='youtube.com']",
        "iframe[src*='youtube-nocookie.com']",
        "iframe[src*='kickstarter.com'][src*='video.html']",
        'object',
        'embed',
    ];
    reframe(selectors.join(','));

    // Object fit polyfill for IE
    if (!Modernizr.objectfit) {
        document.querySelectorAll('.img-container').forEach((el) => {
            const imgUrl = el.querySelector('img').src;
            if (imgUrl) {
                el.style.backgroundImage = 'url(' + imgUrl + ')';
                el.classList.add('compat-object-fit');
            }
        });
    }
});

/*********************
 * FIX BODY
 *********************/

export function fixBody(bodyClass) {
    let scrollPosition = window.pageYOffset;
    bodyElement.classList.add(bodyClass);
    bodyElement.style.top = '-' + scrollPosition + 'px';
    bodyElement.setAttribute('data-scroll', String(scrollPosition));
}

/*********************
 * UNFIX BODY
 *********************/

export function unfixBody(bodyClass) {
    let scrollPosition = bodyElement.getAttribute('data-scroll');
    bodyElement.classList.remove(bodyClass);
    bodyElement.style.removeProperty('top');
    window.scrollTo(0, parseInt(scrollPosition));
}

/*********************
 * ADJUST MENU
 *********************/

function adjustMenu() {
    if (header) {
        const nav = header.querySelector('.main-nav');
        const activeSubMenu = nav.querySelector('.sub-menu.active');

        if (vpWidth < 1024 && prevVpWidth >= 1024) {

            unfixBody('menu-active');
            nav.classList.remove('active', 'sub-menu-active');
            if (activeSubMenu) {
                activeSubMenu.classList.remove('active');
            }

        } else if (vpWidth >= 1024 && prevVpWidth < 1024) {

            unfixBody('menu-active');
            nav.classList.remove('active', 'sub-menu-active');
            if (activeSubMenu) {
                activeSubMenu.classList.remove('active');
            }

        }

        prevVpWidth = vpWidth;
    }
}

/*********************
 * MENU TOGGLE
 *********************/

if (header) {
    const menuToggle = header.querySelector('.menu-toggle');
    if (menuToggle) {

        menuToggle.addEventListener('click', (e) => {
            e.preventDefault();

            const nav = document.querySelector('.main-nav');
            const activeSearchOverlay = document.querySelector('.search-overlay.active');

            if (activeSearchOverlay) {
                activeSearchOverlay.classList.remove('active');
                unfixBody('search-active');
            } else if (nav.classList.contains('active')) {
                nav.classList.remove('active');
                unfixBody('menu-active');
            } else {
                nav.classList.add('active');
                fixBody('menu-active');
            }
        });

    }
}

/*********************
 * SUB MENU
 *********************/

const subMenuParents = header.querySelectorAll('.menu-item-has-children');
if (subMenuParents.length) {

    const nav = header.querySelector('.main-nav');

    subMenuParents.forEach((element) => {

        const link = element.querySelector('a');
        const subMenu = element.querySelector('.sub-menu')

        link.addEventListener('click', (e) => {

            if (vpWidth < 1024 && (element.classList.contains('menu-item-empty-url') || (e.clientX - link.getBoundingClientRect().left) > link.offsetWidth)) {
                e.preventDefault();
                nav.classList.toggle('sub-menu-active');
                subMenu.classList.toggle('active');
            }

        });

    });

}

/*********************
 * SWIPER
 *********************/

// Swiper example, docs: https://swiperjs.com/swiper-api
const swiperSlider = document.querySelector('.swiper');
if (swiperSlider) {
    const swiper = new Swiper('.swiper', {
        //modules: [Navigation, Pagination, etc.],
        loop: false,
        slidesPerView: 1,
        spaceBetween: 10,
        // Responsive breakpoints
        breakpoints: {
            // When window width is >= 480px
            480: {
                slidesPerView: 2,
                spaceBetween: 30,
            },
            // When window width is >= 640px
            640: {
                slidesPerView: 3,
                spaceBetween: 40,
            },
        },

        // If we need pagination
        pagination: {
            el: '.swiper-pagination',
        },

        // Navigation arrows
        navigation: {
            nextEl: '.swiper-button-next',
            prevEl: '.swiper-button-prev',
        },

        // And if we need scrollbar
        scrollbar: {
            el: '.swiper-scrollbar',
        },
    });
}

/*********************
 * LOCAL SCROLL
 *********************/

const links = document.querySelectorAll('a[href*="#"]:not([href="#"])');

links.forEach((element) => {
    const hrefUrl = new URL(element.href);

    element.addEventListener('click', (e) => {

        if (window.location.hostname === hrefUrl.hostname && window.location.pathname === hrefUrl.pathname) {
            e.preventDefault();
            const hashLink = hrefUrl.hash;

            if (hashLink) {
                const linkTarget = document.querySelector(hashLink);
                const spacingBetweenHeader = clamp * 16;

                if (linkTarget) {
                    const targetTop = linkTarget.offsetTop - header.offsetHeight - spacingBetweenHeader;
                    localScroll(document.documentElement, targetTop, 1000);
                }
            }
        }

    });
});

/*********************
 * LOCAL NAV
 *********************/

const localNav = document.querySelector('.local-nav');
if (localNav) {

    let i = 0;

    document.addEventListener('DOMContentLoaded', (e) => {
        localNavPosition()
    });

    document.addEventListener('scroll', (e) => {
        localNavPosition();
    });

    function localNavPosition() {

        const items = localNav.querySelectorAll('li');
        let scrollTrigger = Math.round(window.scrollY + header.offsetHeight + (clamp * 16) + 1);
        let headingTops = [];

        items.forEach((element) => {

            const link = element.querySelector('a').getAttribute('href');
            const linkName = link.replace('#', '');
            const anchorHeading = document.getElementById(linkName);
            headingTops.push(anchorHeading.offsetTop);

        });

        const firstHeadingTop = headingTops[0];
        const lastHeadingTop = headingTops[headingTops.length - 1];

        headingTops.forEach((headingTop, index) => {

            let nextIndex = index + 1;
            let nextHeadingTop = headingTops[nextIndex];

            if (scrollTrigger >= headingTop && scrollTrigger < nextHeadingTop) {

                items[index].classList.add('active');
                i = 0;

            } else if (scrollTrigger < firstHeadingTop) {

                items[0].classList.add('active');
                i = 0;

            } else if (scrollTrigger >= lastHeadingTop) {

                if (i === 0) {
                    for (let key of headingTops.keys()) {
                        items[key].classList.remove('active');
                    }
                    items[headingTops.length - 1].classList.add('active');
                    i++;
                }

            } else {

                items[index].classList.remove('active');
                i = 0;

            }

        });

    }

}

/*********************
 * SEARCH TOGGLE
 *********************/

const searchToggle = header.querySelector('.search-toggle');
if (searchToggle) {
    searchToggle.addEventListener('click', (e) => {
        e.preventDefault();

        const searchOverlay = document.querySelector('.search-overlay');

        if (searchOverlay.classList.contains('active')) {
            searchOverlay.classList.remove('active');
            unfixBody('search-active');
        } else {
            searchOverlay.classList.add('active');
            fixBody('search-active');
        }
    });
}

/*********************
 * HEADER BACKGROUND
 *********************/

let variantHeader = false;

document.addEventListener('DOMContentLoaded', (e) => {
    if (header.classList.contains('variant')) {
        variantHeader = true;
    }

    if (window.scrollY >= clamp * 30 && variantHeader) {
        header.classList.remove('variant');
    }
});

document.addEventListener('scroll', (e) => {
    if (window.scrollY >= clamp * 30 && variantHeader) {
        header.classList.remove('variant');
    } else if (window.scrollY < clamp * 30 && variantHeader && !bodyElement.classList.contains('menu-active') && !bodyElement.classList.contains('popup-active')) {
        header.classList.add('variant');
    }
});

/*********************
 * IMAGE SLIDER
 *********************/

const imageSliders = document.querySelectorAll('.image-slider');
if (imageSliders.length > 0) {

    imageSliders.forEach(imageSlider => {

        const innerImageSlider = imageSlider.querySelector('.inner-image-slider');
        const swiperImages = new Swiper(innerImageSlider, {
            slidesPerView: 'auto',
            speed: 1000
        });

    });

}

/*********************
 * QUOTE SLIDER
 *********************/

const quoteSliders = document.querySelectorAll('.quote-slider');
if (quoteSliders.length > 0) {

    quoteSliders.forEach(quoteSlider => {

        const innerQuoteSlider = quoteSlider.querySelector('.inner-quote-slider');
        const swiperQuotes = new Swiper(innerQuoteSlider, {
            slidesPerView: 'auto',
            speed: 1000
        });

    });

}

/*********************
 * FREQUENTLY ASKED QUESTIONS
 *********************/

const frequentlyAskedQuestions = document.querySelectorAll('.faq-item');
if (frequentlyAskedQuestions.length > 0) {

    frequentlyAskedQuestions.forEach(item => {

        const question = item.querySelector('.faq-question');

        question.addEventListener('click', (e) => {
            e.preventDefault();
            item.classList.toggle('active');
        });

    });

}

/*********************
 * PROGRAM
 *********************/

const programItems = document.querySelectorAll('.categorized-program-item.collapsible');
if (programItems.length > 0) {

    programItems.forEach(item => {

        const head = item.querySelector('.cpi-head');

        head.addEventListener('click', (e) => {
            e.preventDefault();
            item.classList.toggle('open');
        });

    });

}

/*********************
 * CLOSE POPUPS
 *********************/

const popups = document.querySelectorAll('.popup');
if (popups.length > 0) {

    popups.forEach(popup => {

        const closeButton = popup.querySelector('.popup-close-btn');

        closeButton.addEventListener('click', (e) => {
            e.preventDefault();
            popup.classList.remove('active');
            unfixBody('popup-active');
        });

    });

}

/*********************
 * SPEAKER POPUP
 *********************/

const speakerPopup = document.querySelector('.speaker-popup');
if (speakerPopup) {

    const speakerTiles = document.querySelectorAll('.speaker-tile');

    speakerTiles.forEach(tile => {

        tile.addEventListener('click', (e) => {

            let speakerId = tile.getAttribute('data-speaker-id');

            jQuery.ajax({
                url: safe.ajax_url,
                type: 'POST',
                data: 'action=uxin_set_speaker_popup&speaker_id=' + speakerId + '&n=' + safe.ajax_nonce,

                success: function (response) {
                    speakerPopup.querySelector('.speaker-popup-content').innerHTML = response;
                    speakerPopup.classList.add('active');
                    fixBody('popup-active');
                }
            });

        });

    });

}

/*********************
 * INTERSECTION OBSERVER
 *********************/

// convert this into a class for easier use in this and other projects?
/*
const options = {
    root: null, // viewport
    threshold: 0,
    rootMargin: '0px',
};

const animationTriggerObserver = new IntersectionObserver(
    (entries, observer) => {
        entries.forEach((entry) => {
            if (entry.isIntersecting) {
                entry.target.classList.add('animateMe');

                observer.unobserve(entry.target);
            }
        });
    },
    options
);

if (document.querySelectorAll('.svgAni').length > 0) {
    const svgs = document.querySelectorAll('.svgAni');

    svgs.forEach((el) => {
        animationTriggerObserver.observe(el);
    });
}
*/

/*********************
 * COOKIES
 *********************/

export function setCookie(key, value, expiry) {
    let expires = new Date();
    expires.setTime(expires.getTime() + (expiry * 24 * 60 * 60 * 1000));
    document.cookie = key + '=' + value + ';expires=' + expires.toUTCString() + ';path=/';
}

export function getCookie(key) {
    let keyValue = document.cookie.match('(^|;) ?' + key + '=([^;]*)(;|$)');
    return keyValue ? keyValue[2] : null;
}

/*********************
 * HELPER FUNCTION TO THROTTLE EVENTS
 *********************/

function throttle(callback, delay) {
    let previousCall = new Date().getTime();
    return function () {
        let time = new Date().getTime();

        if (time - previousCall >= delay) {
            previousCall = time;
            callback.apply(null, arguments);
        }
    };
}

// TODO fix

/* Page load scripts */
jQuery(document).ready(function ($) {

    jQuery('#toggle-thememenu').on('click', function (e) {
        e.preventDefault();
        //jQuery(this).toggleClass("active");
        jQuery(".theme-menu").toggleClass("active");
        jQuery('.subheader').toggleClass('logo-only');
    });

    jQuery('#toggle-searchmenu').on('click', function (e) {
        e.preventDefault();
        //jQuery(this).toggleClass("active");
        jQuery(".search-menu").toggleClass("active");
        jQuery('.subheader').toggleClass('logo-only');
        jQuery('#s').focus();
    });

    jQuery('.menuclose-btn').on('click', function (e) {
        e.preventDefault();
        jQuery(".theme-menu, .search-menu").removeClass("active");
        jQuery('.subheader').removeClass('logo-only');
    });

    jQuery('.menu-item-has-children .toggle-sub').on('click', function (e) {
        if (vpWidth < 1024) {
            e.preventdefault;
            jQuery(this).parent().toggleClass('active');
            jQuery(this).parent().siblings().removeClass('active');
            jQuery(this).blur();
            return false;
        }
    })

    jQuery('.form-toggle').click(function (e) {
        e.preventDefault();
        var formcontainer = jQuery('#form-container');
        formcontainer.toggleClass("active");

        jQuery('html, body').animate({
            scrollTop: formcontainer.offset().top - 120
        }, 1000, function () /**/ {
        });

        //reset on click out of container
        formcontainer.click(function () {
            if (formcontainer.hasClass('active')) {
                formcontainer.removeClass('active');
            }
        }).children().click(function (e) {
            e.stopPropagation();
        });
    });

    jQuery('#closeform-icon').click(function () {
        jQuery(this).parents('#form-container').removeClass('active');
    });

    jQuery('.comments-toggle').on('click', function () {
        jQuery(this).toggleClass('active');
        jQuery('.comments-area').toggleClass('active');
    });

    jQuery('.readmore').on('click', function () {
        jQuery(this).toggleClass('active');
        jQuery(this).parents('.eventlist-tile, .joblist-tile').find('.readmorecontent').toggleClass('active');
    });

    jQuery('a', '.thememenu-catlist').on('click', function (e) {
        e.preventDefault();

        var mainid = jQuery(this).parent().data('catid');
        var target = jQuery('*[data-parentcat-id="' + mainid + '"]');

        jQuery('.thememenu-subtab').not(target).removeClass('active');
        jQuery('.thememenu-catlist').addClass('hidden');
        target.addClass('active');
    });

    jQuery('.closetab').on('click', function (e) {

        e.preventDefault();
        jQuery(this).parent().removeClass('active');
        jQuery('.thememenu-catlist').removeClass('hidden');
    });

    //todo reset after viewport scaling
    if (jQuery('.side-infocol').length > 0 && vpWidth > 1024) {

        var triggerpos;
        var currentpos;
        var sidebar = jQuery('.side-infocol');
        var trigger = jQuery('.article-container');
        var header = jQuery('.header');

        //stick sidebar
        $(window).scroll(function (e) {
            currentpos = $(window).scrollTop();
            triggerpos = trigger.offset().top - header.innerHeight() - 32;

            if (currentpos >= triggerpos) {
                sidebar.addClass('stick');
            } else {
                sidebar.removeClass('stick');
            }

            var end = (trigger.offset().top + trigger.innerHeight()) - sidebar.innerHeight();

            if (currentpos > end) {
                sidebar.addClass('hidden');
            } else {
                sidebar.removeClass('hidden');
            }
        });

        if (jQuery('.readprogress').length > 0) {
            var center = viewport.height / 2;
            var content = jQuery('.article-content');
            var articleStart = content.offset().top;
            var articleHeight = content.innerHeight();
            var percentage;
            var progressbar = jQuery('.readprogress');
            var progressinner = jQuery('.progress-innner');
            var newval;

            $(window).scroll(function (e) {
                if (((currentpos + center) - articleStart) > 0 && ((currentpos + center) - articleStart) < articleHeight) {

                    percentage = ((currentpos + center) - articleStart) / articleHeight;

                    if (percentage < 0.5) {
                        newval = Math.ceil(360 * percentage);

                        progressbar.removeClass('over50');
                        progressinner.css('transform', 'rotate(' + newval + 'deg)');

                    } else if (percentage > 0.5 && percentage < 1) {
                        newval = Math.ceil(360 * (percentage - 0.5));

                        progressbar.addClass('over50');
                        progressinner.css('transform', 'rotate(' + newval + 'deg)');
                    }
                }
            });
        }
    }

    if (jQuery('.course-sidecol').length > 0 && vpWidth > 768) {

        var triggerpos;
        var currentpos;
        var sidebar = jQuery('.course-sidecol');
        var trigger = jQuery('.course-main');
        var header = jQuery('.header');

        //stick sidebar
        $(window).scroll(function (e) {
            currentpos = $(window).scrollTop();
            triggerpos = trigger.offset().top - header.innerHeight() - 16;

            if (currentpos >= triggerpos) {
                sidebar.addClass('stick');
            } else {
                sidebar.removeClass('stick');
            }

            var end = (trigger.offset().top + trigger.innerHeight() - 100) - sidebar.outerHeight();

            if (currentpos > end) {
                sidebar.addClass('hidden');
            } else {
                sidebar.removeClass('hidden');
            }
        });
    }

    if (jQuery('.sb-page-sidecol').length > 0 && vpWidth > 768) {

        var triggerpos;
        var currentpos;
        var sidebar = jQuery('.sb-page-sidecol');
        var trigger = jQuery('.sb-page-main');
        var header = jQuery('.header');

        //stick sidebar
        $(window).scroll(function (e) {
            currentpos = $(window).scrollTop();
            triggerpos = trigger.offset().top - header.innerHeight() - 16;

            if (currentpos >= triggerpos) {
                sidebar.addClass('stick');
            } else {
                sidebar.removeClass('stick');
            }

            var end = (trigger.offset().top + trigger.innerHeight() - 100) - sidebar.outerHeight();

            if (currentpos > end) {
                sidebar.addClass('hidden');
            } else {
                sidebar.removeClass('hidden');
            }
        });
    }

    jQuery('.showcatlist').on('click', function (e) {
        e.preventDefault();
        jQuery(this).siblings('.catlist').removeClass('hidden');
        jQuery(this).hide();
    });

    jQuery('.cross-bg').each(function () {
        buildBackground($(this))
    });

    jQuery('a', '.homeheader-catlist').hover(
        function () {
            var catid = jQuery(this).parent('li').data('catid');
            jQuery('.home-header').addClass('cat' + catid);
            jQuery('.cross-bg', '.home-header').addClass('cat' + catid);
            shuffleBackground();

        }, function () {
            var catid = jQuery(this).parent('li').data('catid');
            jQuery('.home-header').removeClass('cat' + catid);
            jQuery('.cross-bg', '.home-header').removeClass('cat' + catid);
            shuffleBackground();
        }
    );

    jQuery('li', '.tab-selector').on('click', function () {
        var $this = jQuery(this);
        $this.addClass('current');
        $this.siblings().removeClass('current');

        var targetNumber = jQuery(this).data('tabid');
        var target = jQuery('.contenttab[data-tabid="' + targetNumber + '"]');

        target.addClass('open');
        target.siblings().removeClass('open');

    });

    if (jQuery('.fixedpage-background').length > 0) {

        updateFixedBgColor();

        jQuery(window).on('scroll', function () {

            updateFixedBgColor();
        });
    }

    //home story slider
    if (jQuery('.quotesslider').length > 0) {

        jQuery('.quotesslider').slick({
            autoplay: false,
            speed: 1200,
            autoplaySpeed: 5000,
            fade: false,
            infinite: false,
            centerMode: false,
            slidesToShow: 1,
            pauseOnHover: false,
            arrows: true,
            nextArrow: jQuery('.slick-next'),
            waitForAnimate: false,
            dots: false,
            zIndex: 5
        });
    }


    //home new product slider
    if (jQuery('.product-slider').length > 0) {

        jQuery('.product-slider').slick({
            autoplay: false,
            speed: 1000,
            autoplaySpeed: 5000,
            fade: false,
            infinite: false,
            centerMode: false,
            slidesToShow: 1,
            pauseOnHover: false,
            arrows: false,
            waitForAnimate: false,
            dots: true,
            zIndex: 5,
            mobileFirst: true,
            responsive: [
                {
                    breakpoint: 768,
                    settings: {
                        slidesToShow: 2,
                        //slidesToScroll: 2,
                    }
                },
                {
                    breakpoint: 1024,
                    settings: {
                        slidesToShow: 3,
                        //slidesToScroll: 3
                    }
                }
            ]
        });
    }

    //Event speaker vid slider
    if (jQuery('.speaker-vid-slider').length > 0) {

        jQuery('.speaker-vid-slider').slick({
            autoplay: false,
            speed: 1000,
            autoplaySpeed: 5000,
            fade: false,
            infinite: false,
            centerMode: false,
            slidesToShow: 1,
            pauseOnHover: false,
            arrows: false,
            waitForAnimate: false,
            dots: true,
            zIndex: 5,
            mobileFirst: true,
            responsive: [
                {
                    breakpoint: 768,
                    settings: {
                        slidesToShow: 1,
                        //slidesToScroll: 2,
                    }
                },
                {
                    breakpoint: 1024,
                    settings: {
                        slidesToShow: 2,
                        //slidesToScroll: 3
                    }
                }
            ]
        });
    }

    //product/about review slider

    if (jQuery('.review-slider').length > 0) {

        jQuery('.review-slider').slick({
            autoplay: false,
            speed: 500,
            autoplaySpeed: 5000,
            fade: false,
            infinite: false,
            centerMode: false,
            slidesToShow: 1,
            pauseOnHover: false,
            arrows: true,
            nextArrow: jQuery('.slick-next'),
            prevArrow: jQuery('.slick-prev'),
            waitForAnimate: false,
            dots: false,
            zIndex: 5,
            mobileFirst: true,
            responsive: [
                {
                    breakpoint: 1024,
                    settings: {
                        slidesToShow: 2,
                        //slidesToScroll: 2,
                    }
                }
            ]
        });

    }


    jQuery('.accordeon-item').on('click', function () {
        var $this = jQuery(this);
        var itemNr = $this.data('item');

        $this.toggleClass('active');
        $this.siblings().removeClass('active');

        jQuery('.img-container[data-item="' + itemNr + '"]', '.infoimages-container').addClass('active');
        jQuery('.img-container[data-item="' + itemNr + '"]', '.infoimages-container').siblings().removeClass('active');
    });

    jQuery('.faq-item-old').on('click', function () {
        var $this = jQuery(this);

        $this.toggleClass('active');
        $this.siblings().removeClass('active');

    });

    jQuery('.programitem-head').on('click', function (e) {
        e.stopPropagation();
        var $this = jQuery(this);

        var parent = $this.parents('.program-listitem')

        if (parent.hasClass('active')) {
            parent.removeClass('active');
            parent.find('button').html('Show more info');
        } else {
            parent.addClass('active');
            parent.find('button').html('Hide more info');
        }

        var siblings = parent.siblings();
        siblings.removeClass('active');
        siblings.find('button').html('Show more info');

    });

    jQuery('.speaker-tile-legacy', '.speakers-speakersection').hover(
        function () {
            var $this = jQuery(this);
            jQuery('.speaker-tile-legacy').not($this).addClass('inactive');
        }, function () {
            jQuery('.speaker-tile-legacy').removeClass('inactive');
        }
    );

    jQuery('.speaker-tile-legacy', '.speakers-speakersection, .onepage-speakers').on('click', function () {
        var $this = jQuery(this);
        var id = $this.data('id');
        var matchbio = jQuery('.speaker-bio[data-id="' + id + '"]');

        matchbio.toggleClass('active');
        matchbio.siblings().removeClass('active');
        jQuery('body').addClass('noscroll');

        matchbio.find('.bio-close').focus();
    });


    jQuery('body').on('keypress', function (e) {
        if (e.keyCode === 32 || e.keyCode === 13) {
            var focused = jQuery(document.activeElement);

            if (focused.hasClass('speaker-tile-legacy')) {
                document.activeElement.click();
            }
        }
    });

    jQuery('.bio-close').on('click', function () {
        jQuery(this).parents('.speaker-bio').removeClass('active');
        jQuery('body').removeClass('noscroll');
    });

    // Shop actions
    jQuery('input', '.woocommerce-cart-form').on('input', function () {
        jQuery("button[name='update_cart']").addClass('active');
        jQuery(".actions").addClass('active');
        jQuery(".woocommerce-notices-wrapper").addClass('hidden');
    });

    jQuery('.coupon-toggle').on('click', function () {
        jQuery(".actions").addClass('active');
        jQuery(".coupon").addClass('active');
        jQuery(this).hide();
    });

    jQuery('.customizer-togglenext').on('click', function (e) {
        e.preventDefault();
        e.stopPropagation();

        var parentlistitem = jQuery(this).parents('li');

        var valid = true;

        jQuery('input', parentlistitem).each(function (index, value) {
            if (!jQuery(this)[0].checkValidity()) {
                valid = false;
                jQuery(this).addClass('error');
            } else {
                jQuery(this).removeClass('error');
            }
        });

        if (valid === true) {
            parentlistitem.removeClass('active is-invalid');
            parentlistitem.next().addClass('active istouched');
            inputsToField(parentlistitem);
        }
    });

    jQuery('.order-product-details').on('click', function () {
        inputsToField(jQuery('.customizer-step.active'));

        var parentlistitem = jQuery(this).parents('li');
        parentlistitem.addClass('active istouched');
        parentlistitem.siblings().removeClass('active');
    });

    jQuery('.pencil').on('click', function () {


        var parentlistitem = jQuery(this).parents('li');
        parentlistitem.addClass('active istouched');
        parentlistitem.siblings().removeClass('active');
    });

    jQuery('.customizer-proceed').on('click', function (e) {
        //validate an extra time

        var valid = true;

        jQuery('input, select', jQuery('#customizer-form')).not('input[type=submit]').each(function (index, value) {
            if (!jQuery(this)[0].checkValidity()) {
                valid = false;
                jQuery(this).addClass('error');
                jQuery(this).parents('.customizer-step').addClass('is-invalid');
            } else {
                jQuery(this).removeClass('error');
            }
        });

        if (valid === false) {
            e.preventDefault();
            return false;
        }

    });

    var playBtn = jQuery('.play-btn');
    if (playBtn.lenght !== 0) {
        playBtn.click(function () {
            var $this = jQuery(this);
            $this.parents('.video-container').addClass('play');
            $this.hide();

            var iframe = $this.parents('.video-container').find('iframe')[0];
            var player = new Vimeo.Player(iframe);
            player.play();
        });
    }

    jQuery(".variations_form").on("woocommerce_update_variation_values", function () {

        jQuery(".variations_form option").not('.enabled').prop('disabled', true);

    })

    jQuery('.checkout-btn-container button').on('click', function () {
        jQuery(this).css("pointer-events", "none");

        setTimeout(() => {
            jQuery(this).css("pointer-events", "auto");
        }, "5000")

    });

});

function updateFixedBgColor() {
    var readheight = viewport.height / 3;
    var currentpos = jQuery(window).scrollTop() + readheight;

    jQuery('.color-section').each(function () {

        var top = jQuery(this).offset().top;
        var bot = top + jQuery(this).innerHeight();

        // console.log('current=' + currentpos + ' top=' + top + ' bot=' + bot);


        if (currentpos > top && currentpos < bot) {

            var color = jQuery(this).data('color');
            var colorClasses = ['darker-blue', 'dark-blue', 'mediumdark-blue', 'blue', 'mediumlight-blue', 'light-blue', 'lighter-blue'];
            var bgcrosses = jQuery('.cross-bg');
            var fixebg = jQuery('.fixedpage-background');

            if (!bgcrosses.hasClass(color)) {

                var tempClasses = colorClasses;
                removeA(tempClasses, color);

                bgcrosses.removeClass(tempClasses);
                fixebg.removeClass(tempClasses);

                bgcrosses.addClass(color);
                fixebg.addClass(color);

                shuffleBackground();
            }
        }
    });
}

/*** helper function to remove needed class from classes array **/
function removeA(arr) {
    var what, a = arguments, L = a.length, ax;
    while (L > 1 && arr.length) {
        what = a[--L];
        while ((ax = arr.indexOf(what)) !== -1) {
            arr.splice(ax, 1);
        }
    }
    return arr;
}

function inputsToField(step) {
    var html = '<ul>';

    jQuery('input[type=text]', step).each(function (index, value) {

        html += '<li>' + jQuery(this).val() + '</li>';
    });

    html += '</ul>';

    jQuery('.inserted-data', step).html(html);
}

function buildBackground(container) {

    var x = Math.ceil((container.width() / 2) / 96) - 1;
    var y = Math.ceil(container.height() / 96) - 1;

    var xii = x;
    var yii = y;

    while (xii >= 0) {

        while (yii >= 0) {
            let right = xii * 96;
            let top = yii * 96;

            BackgroundShape(right, top, container, yii, xii, x);

            yii--;
        }

        yii = y;
        xii--;
    }

    jQuery('#header-bg').data('xcount', x);
}

function BackgroundShape(right, top, container, yii, xii, x) {
    var div = document.createElement("div");

    var id = xii + '-' + yii;

    var min = 3;
    var max = 18;

    max = Math.round(max - ((max - min) * (xii / x)));

    var percentage = (Math.floor(Math.random() * max) + min) / 100;


    var shadowX = 2 + 'px';
    var shadowY = 2 + 'px';
    var shadowB = 2 + 'px';
    var shadowO = percentage;


    var shape1InlineCss = 'style="filter: drop-shadow(' + shadowX + ' ' + shadowY + ' ' + shadowB + ' rgba(0,0,0,' + shadowO + '))"';
    var shape2InlineCss = 'style="filter: drop-shadow(-1px -1px 1px rgba(255,255,255,' + shadowO + '))"';

    div.innerHTML = '<svg class="cross-shape" ' + shape1InlineCss + ' xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48.689 48.519"><path class="main-path" d="M2550.266,4298.1l-15.83-15.775,8.213-8.367,15.047,14.592,15.25-14.592,8.744,8.367-14.85,15.775,14.85,14.521-8.744,8.445-15.25-14.412-15.047,14.412-8.213-8.445Z" transform="translate(-2533.718 -4273.252)"/></svg>';
    div.innerHTML += '<svg class="cross-shape2" ' + shape2InlineCss + ' xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48.689 48.519"><path class="main-path" d="M2550.266,4298.1l-15.83-15.775,8.213-8.367,15.047,14.592,15.25-14.592,8.744,8.367-14.85,15.775,14.85,14.521-8.744,8.445-15.25-14.412-15.047,14.412-8.213-8.445Z" transform="translate(-2533.718 -4273.252)"/></svg>';
    div.style.right = right + 16 + 'px';
    div.style.top = top + 16 + 'px';
    div.className = 'shape';
    div.id = id;

    container.append(div);
}

function shuffleBackground() {
    var crosses = jQuery('.shape', '#header-bg');

    crosses.each(function () {

        var min = 3;
        var max = 18;

        var id = jQuery(this).attr('id');
        var xii = id.substr(0, id.indexOf('-'));

        var x = jQuery('#header-bg').data('xcount');

        max = Math.round(max - ((max - min) * (xii / x)));
        //console.log(max);

        var percentage = (Math.floor(Math.random() * max) + min) / 100;

        //console.log(percentage);

        var svg1 = jQuery(this).find('.cross-shape');
        var svg1CurrentFilter = svg1.css('filter');
        var svg1Separate_options = svg1CurrentFilter.split(' ');


        var svg1NewFilter = ' drop-shadow(' + svg1Separate_options[4].match(/\d+/g).map(Number) + 'px ' + svg1Separate_options[5].match(/\d+/g).map(Number) + 'px ' + svg1Separate_options[6].match(/\d+/g).map(Number) + 'px rgba(0,0,0,' + percentage + '))';


        svg1.css('filter', svg1NewFilter);

    });
}